import { CORE_API_DOMAIN } from '../../API_routes'
import { IResponse, IOperatorConfig } from '../interfaces'
import axios from '../axiosInstance'

const apiPrefix = `${CORE_API_DOMAIN}/operator-configs`

export const getOperatorConfig = () => axios.get<{ data: IOperatorConfig }>(apiPrefix)

export const updateOperatorConfig = (data: IOperatorConfig) => axios.post(apiPrefix, data)

export const generateIntegrationApiKey = () =>
  axios.post<IResponse<{ value: string }>>(`${apiPrefix}/integration-api-token`)

export const revokeIntegrationApiKey = () => axios.delete<IResponse<null>>(`${apiPrefix}/integration-api-token`)
