import { CORE_API_DOMAIN } from '../../API_routes'
import { IResponse, ITableResource } from '../interfaces'
import { IAlarm, GetAlarmsParams } from '../models/alarm'
import axios from '../axiosInstance'

const apiPrefix = `${CORE_API_DOMAIN}/failures`

export const getAlarms = ({
  operator_device_id,
  type,
  error_code,
  error_data,
  acknowledge,
  limit,
  page,
}: GetAlarmsParams) =>
  axios.get<IResponse<ITableResource<IAlarm>>>(apiPrefix, {
    params: {
      'filter[operator_device_id]': operator_device_id,
      'filter[type]': type,
      'filter[error_code]': error_code,
      'filter[error_data]': error_data,
      'filter[acknowledge]': acknowledge,
      limit,
      page,
    },
  })

export const getAlarmsCount = ({ type }: { type: string }) =>
  axios.get<IResponse<{ total: number }>>(`${apiPrefix}/count`, {
    params: {
      'filter[type]': type,
    },
  })

export const acknowledgeAlarms = (ids: string[]) => axios.put<IResponse<boolean>>(`${apiPrefix}/acknowledge`, { ids })
