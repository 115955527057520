import React from 'react'
import { InputAdornment, IconButton, Tooltip } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { copyToClipboard } from 'common/helper/helper'

export type CopyInputAdornmentProps = {
  text?: string | null
  getText?: () => string | null
  position?: 'start' | 'end'
  disabled?: boolean
}

const CopyInputAdornment = ({ text, getText, position = 'end', disabled }: CopyInputAdornmentProps) => (
  <InputAdornment position={position}>
    <Tooltip title="Copy to clipboard">
      <IconButton
        edge={position}
        disabled={disabled || (!text && !getText)}
        onClick={() => copyToClipboard(text || getText?.())}
      >
        <ContentCopyIcon />
      </IconButton>
    </Tooltip>
  </InputAdornment>
)

export default React.memo(CopyInputAdornment)
